import { createSlice } from '@reduxjs/toolkit'

// Define the initial state for datosComparativaFormateados
const initialDatosComparativaFormateadosState = {
  consumos: [],
  precioEnergiaCliente: null,
  costeOperativoComercial: [],
  costeOperativoPotenciaComercial: null,
  sip: [],
  idProducto: null,
  potenciaEstudio: null
}

// Create the slice for datosComparativaFormateados
const datosAnalisisCupsSlice = createSlice({
  name: 'datosAnalisisCups',
  initialState: initialDatosComparativaFormateadosState,
  reducers: {
    setReduxDatosAnalisisCups: (state, action) => {
      state.datosCups = action.payload
  }}
})

const datosAnalisisCupsProductosSlice = createSlice({
  name: 'datosAnalisisCupsProductos',
  initialState: { datosCupsProducto: [] },
  reducers: {
    setReduxDatosAnalisisCupsProductos: (state, action) => {
      state.datosCupsProducto  = action.payload
    }
  }
})

// Export the actions

export const { setReduxDatosAnalisisCups } = datosAnalisisCupsSlice.actions
export const { setReduxDatosAnalisisCupsProductos } = datosAnalisisCupsProductosSlice.actions

// Combine the slices into one reducer
const storeAnalisisCups = {
  reduxDatosAnalisisCups: datosAnalisisCupsSlice.reducer,
  reduxDatosAnalisisCupsProductos: datosAnalisisCupsProductosSlice.reducer
}

export default storeAnalisisCups

