import { createSlice } from '@reduxjs/toolkit'

// Create a slice for filtrosBusqueda
const representantesFiltrosBusquedaSlice = createSlice({
  name: 'filtrosBusqueda',
  initialState: [],
  reducers: {
    setReduxrepresentantesFiltrosBusqueda: (state, action) => action.payload
  }
})

// Create a slice for page
const representantesPageSlice = createSlice({
  name: 'page',
  initialState: 1,
  reducers: {
    setReduxrepresentantesPage: (state, action) => action.payload
  }
})

// Create a slice for limit
const representantesLimitSlice = createSlice({
  name: 'limit',
  initialState: 10,
  reducers: {
    setReduxrepresentantesLimit: (state, action) => action.payload
  }
})

// Create a slice for countRows
const representantesCountRowsSlice = createSlice({
  name: 'countRows',
  initialState: 0,
  reducers: {
    setReduxrepresentantesCountRows: (state, action) => action.payload
  }
})

// Create a slice for cupDatos
const representantesDatosSlice = createSlice({
  name: 'representantesDatos',
  initialState: [],
  reducers: {
    setReduxrepresentantesDatos: (state, action) => action.payload
  }
})

// Export the actions
export const { setReduxrepresentantesFiltrosBusqueda } = representantesFiltrosBusquedaSlice.actions
export const { setReduxrepresentantesPage } = representantesPageSlice.actions
export const { setReduxrepresentantesLimit } = representantesLimitSlice.actions
export const { setReduxrepresentantesCountRows } = representantesCountRowsSlice.actions
export const { setReduxrepresentantesDatos } = representantesDatosSlice.actions

// Combine the slices into one reducer
const storeRepresentantes = {
  reduxFiltrosBusquedaC: representantesFiltrosBusquedaSlice.reducer,
  reduxPageC: representantesPageSlice.reducer,
  reduxLimitC: representantesLimitSlice.reducer,
  reduxCountRowsC: representantesCountRowsSlice.reducer,
  reduxrepresentantesDatosC: representantesDatosSlice.reducer
}

export default storeRepresentantes

