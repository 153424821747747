import axios, {AxiosPromise}  from 'axios'
import jwtDefaultConfig from './jwtDefaultConfig'

export default class JwtService {
  // ** jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig }

  // ** For Refreshing Token
  isAlreadyFetchingAccessToken = false

  // ** For Refreshing Token
  subscribers = []

  constructor(jwtOverrideConfig) {
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }

    // ** Request Interceptor
    axios.interceptors.request.use(
      config => {
        // ** Get token from localStorage
        let accessToken = this.getToken()

        console.log('valor de accessToken: ', accessToken)

        // ** If token is present add it to request's Authorization Header
        if (accessToken) {
          // ** eslint-disable-next-line no-param-reassign
          accessToken.replace(/['"]+/g, '')

          accessToken = accessToken.replace(/^"(.*)"$/, '$1')
          console.log('valor de accessToken después: ', accessToken)
          config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
        }
        return config
      },
      error => Promise.reject(error)
    )

    // ** Add request/response interceptor

    axios.interceptors.response.use(
      response => {
        console.log('valor de response en interceptor: ', response)
        if (typeof response.data === 'string') {
          response.data = JSON.parse(response.data)
        }
        return response
      },
      error => {
        const { config, response } = error
        console.log('valor de config en interceptors: ', config)
        console.log('valor de response en interceptors: ', response)
        const originalRequest = config
        console.log('originalRequest en interceptor: ', originalRequest)
    
        if (response && response.status === 401) {
          if (!this.isAlreadyFetchingAccessToken) {
            this.isAlreadyFetchingAccessToken = true
            return this.refreshToken().then(r => {
              this.isAlreadyFetchingAccessToken = false
    
              let accessToken = r.data.accessToken
              let refreshToken = r.data.refreshToken
    
              console.log('valores de accessToken en antes responde 401: ', accessToken)
              console.log('valores de refreshToken en antes responde 401: ', refreshToken)
    
              accessToken = accessToken.replace(/^"(.*)"$/, '$1')
              refreshToken = refreshToken.replace(/^"(.*)"$/, '$1')
    
              console.log('valores de accessToken en responde 401: ', accessToken)
              console.log('valores de refreshToken en responde 401: ', refreshToken)
    
              // ** Update accessToken in localStorage
              this.setToken(accessToken)
              this.setRefreshToken(refreshToken)
              this.onAccessTokenFetched(accessToken)
    
              // Retry the original request with the new token
              originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
              return axios(originalRequest)
            }).catch(err => {
              // If refresh token fails, reject with the error
              return Promise.reject(err)
            })
          } else {
            // Wait for the new token
            return new Promise(resolve => {
              this.addSubscriber(accessToken => {
                originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
                resolve(axios(originalRequest))
              })
            })
          }
        } else if (response && (response.status === 500 || response.status === 400)) {
          // Handle 500 and 400 errors
          console.error(`Server error (${response.status}):`, error.message)
          // You can add custom error handling here if needed
          // For example, you could dispatch an action to show an error message in your UI
          return Promise.reject(error)
        }
    
        // For all errors, including 500 and 400, reject the promise
        return Promise.reject(error)
      }
    )
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter(callback => callback(accessToken))
  }

  addSubscriber(callback) {
    this.subscribers.push(callback)
  }

  getToken() {
    return localStorage.getItem(this.jwtConfig.storageTokenKeyName)
  }

  getRefreshToken() {
    return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName)
  }

  setToken(value) {
    console.log('valor de value en setToken', value)
    localStorage.setItem(this.jwtConfig.storageTokenKeyName, value)
  }

  setRefreshToken(value) {
    localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value)
  }

  login(...args) {
    return axios.post(this.jwtConfig.loginEndpoint, ...args)
  }

  register(...args) {
    return axios.post(this.jwtConfig.registerEndpoint, ...args)
  }

  refreshToken() {
    let refreshTokenLocal = this.getRefreshToken()
    refreshTokenLocal = refreshTokenLocal.replace(/^"(.*)"$/, '$1')
    return axios.post(this.jwtConfig.refreshEndpoint, {
      refreshToken: refreshTokenLocal
    })
  }
}
