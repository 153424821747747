import { createSlice } from '@reduxjs/toolkit'

// Create a slice for filtrosBusqueda
const propuestasFiltrosBusquedaSlice = createSlice({
  name: 'filtrosBusqueda',
  initialState: [],
  reducers: {
    setReduxPropuestasFiltrosBusqueda: (state, action) => action.payload
  }
})

// Create a slice for page
const propuestasPageSlice = createSlice({
  name: 'page',
  initialState: 1,
  reducers: {
    setReduxPropuestasPage: (state, action) => action.payload
  }
})

// Create a slice for limit
const propuestasLimitSlice = createSlice({
  name: 'limit',
  initialState: 10,
  reducers: {
    setReduxPropuestasLimit: (state, action) => action.payload
  }
})

// Create a slice for countRows
const propuestasCountRowsSlice = createSlice({
  name: 'countRows',
  initialState: 0,
  reducers: {
    setReduxPropuestasCountRows: (state, action) => action.payload
  }
})

// Create a slice for cupDatos
const propuestasDatosSlice = createSlice({
  name: 'cupDatos',
  initialState: [],
  reducers: {
    setReduxPropuestasDatos: (state, action) => action.payload
  }
})

// Export the actions
export const { setReduxPropuestasFiltrosBusqueda } = propuestasFiltrosBusquedaSlice.actions
export const { setReduxPropuestasPage } = propuestasPageSlice.actions
export const { setReduxPropuestasLimit } = propuestasLimitSlice.actions
export const { setReduxPropuestasCountRows } = propuestasCountRowsSlice.actions
export const { setReduxPropuestasDatos } = propuestasDatosSlice.actions

// Combine the slices into one reducer
const storePropuestas = {
  reduxFiltrosBusquedaP: propuestasFiltrosBusquedaSlice.reducer,
  reduxPageP: propuestasPageSlice.reducer,
  reduxLimitP: propuestasLimitSlice.reducer,
  reduxCountRowsP: propuestasCountRowsSlice.reducer,
  reduxPropuestasDatosP: propuestasDatosSlice.reducer
}

export default storePropuestas

