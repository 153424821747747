import { createSlice } from '@reduxjs/toolkit'

// Create a slice for filtrosBusqueda
const representantesAdminFiltrosBusquedaSlice = createSlice({
  name: 'filtrosBusqueda',
  initialState: [],
  reducers: {
    setReduxrepresentantesAdminFiltrosBusqueda: (state, action) => action.payload
  }
})

// Create a slice for page
const representantesAdminPageSlice = createSlice({
  name: 'page',
  initialState: 1,
  reducers: {
    setReduxrepresentantesAdminPage: (state, action) => action.payload
  }
})

// Create a slice for limit
const representantesAdminLimitSlice = createSlice({
  name: 'limit',
  initialState: 10,
  reducers: {
    setReduxrepresentantesAdminLimit: (state, action) => action.payload
  }
})

// Create a slice for countRows
const representantesAdminCountRowsSlice = createSlice({
  name: 'countRows',
  initialState: 0,
  reducers: {
    setReduxrepresentantesAdminCountRows: (state, action) => action.payload
  }
})

// Create a slice for cupDatos
const representantesAdminDatosSlice = createSlice({
  name: 'representantesAdminDatos',
  initialState: [],
  reducers: {
    setReduxrepresentantesAdminDatos: (state, action) => action.payload
  }
})

// Export the actions
export const { setReduxrepresentantesAdminFiltrosBusqueda } = representantesAdminFiltrosBusquedaSlice.actions
export const { setReduxrepresentantesAdminPage } = representantesAdminPageSlice.actions
export const { setReduxrepresentantesAdminLimit } = representantesAdminLimitSlice.actions
export const { setReduxrepresentantesAdminCountRows } = representantesAdminCountRowsSlice.actions
export const { setReduxrepresentantesAdminDatos } = representantesAdminDatosSlice.actions

// Combine the slices into one reducer
const storeRepresentantesAdmin = {
  reduxFiltrosBusquedaAdmin: representantesAdminFiltrosBusquedaSlice.reducer,
  reduxPageAdmin: representantesAdminPageSlice.reducer,
  reduxLimitAdmin: representantesAdminLimitSlice.reducer,
  reduxCountRowsAdmin: representantesAdminCountRowsSlice.reducer,
  reduxrepresentantesAdminDatosAdmin: representantesAdminDatosSlice.reducer
}

export default storeRepresentantesAdmin

