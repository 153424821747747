import { createSlice } from '@reduxjs/toolkit'

// Create a slice for filtrosBusqueda
const clientesFiltrosBusquedaSlice = createSlice({
  name: 'filtrosBusqueda',
  initialState: [],
  reducers: {
    setReduxclientesFiltrosBusqueda: (state, action) => action.payload
  }
})

// Create a slice for page
const clientesPageSlice = createSlice({
  name: 'page',
  initialState: 1,
  reducers: {
    setReduxclientesPage: (state, action) => action.payload
  }
})

// Create a slice for limit
const clientesLimitSlice = createSlice({
  name: 'limit',
  initialState: 10,
  reducers: {
    setReduxclientesLimit: (state, action) => action.payload
  }
})

// Create a slice for countRows
const clientesCountRowsSlice = createSlice({
  name: 'countRows',
  initialState: 0,
  reducers: {
    setReduxclientesCountRows: (state, action) => action.payload
  }
})

// Create a slice for cupDatos
const clientesDatosSlice = createSlice({
  name: 'clientesDatos',
  initialState: [],
  reducers: {
    setReduxclientesDatos: (state, action) => action.payload
  }
})

// Export the actions
export const { setReduxclientesFiltrosBusqueda } = clientesFiltrosBusquedaSlice.actions
export const { setReduxclientesPage } = clientesPageSlice.actions
export const { setReduxclientesLimit } = clientesLimitSlice.actions
export const { setReduxclientesCountRows } = clientesCountRowsSlice.actions
export const { setReduxclientesDatos } = clientesDatosSlice.actions

// Combine the slices into one reducer
const storeClientes = {
  reduxFiltrosBusquedaC: clientesFiltrosBusquedaSlice.reducer,
  reduxPageC: clientesPageSlice.reducer,
  reduxLimitC: clientesLimitSlice.reducer,
  reduxCountRowsC: clientesCountRowsSlice.reducer,
  reduxclientesDatosC: clientesDatosSlice.reducer
}

export default storeClientes

