// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import auth from './authentication'
import todo from '@src/views/apps/todo/store'
import chat from '@src/views/apps/chat/store'
import users from '@src/views/apps/user/store'
import email from '@src/views/apps/email/store'
import kanban from '@src/views/apps/kanban/store'
import invoice from '@src/views/apps/invoice/store'
import calendar from '@src/views/apps/calendar/store'
import ecommerce from '@src/views/apps/ecommerce/store'
import dataTables from '@src/views/tables/data-tables/store'
import permissions from '@src/views/apps/roles-permissions/store'
import storeCups from '@src/views/pages/cups-listado/store/store'
import storeAnalisisCups from '@src/views/pages/cups-listado/comparativa/store/store'
import storePropuestas from '@src/views/pages/propuestas/store/store'
import storeContratos from '@src/views/pages/contratos/store/store'
import storeClientes from '@src/views/pages/clientes/store/store'
import storeRepresentantes from '../views/pages/representantes/store/store'
import storeRepresentantesAdmin from '../views/pages/representantes-gestion/view-admin/store/store'
import storeClientesAdmin from '../views/pages/clientes-gestion/view-admin/store/store'

const rootReducer = {
  auth,
  todo,
  chat,
  email,
  users,
  kanban,
  navbar,
  layout,
  invoice,
  calendar,
  ecommerce,
  dataTables,
  permissions, 
  ...storeCups,
  ...storeAnalisisCups,
  ...storePropuestas,
  ...storeContratos,
  ...storeClientes,
  ...storeRepresentantes,
  ...storeRepresentantesAdmin,
  ...storeClientesAdmin
}

export default rootReducer
