import { createSlice } from '@reduxjs/toolkit'

// Create a slice for filtrosBusqueda
const clientesFiltrosBusquedaSlice = createSlice({
  name: 'filtrosBusqueda',
  initialState: [],
  reducers: {
    setReduxclientesAdminFiltrosBusqueda: (state, action) => action.payload
  }
})

// Create a slice for page
const clientesPageSlice = createSlice({
  name: 'page',
  initialState: 1,
  reducers: {
    setReduxclientesAdminPage: (state, action) => action.payload
  }
})

// Create a slice for limit
const clientesLimitSlice = createSlice({
  name: 'limit',
  initialState: 10,
  reducers: {
    setReduxclientesAdminLimit: (state, action) => action.payload
  }
})

// Create a slice for countRows
const clientesCountRowsSlice = createSlice({
  name: 'countRows',
  initialState: 0,
  reducers: {
    setReduxclientesAdminCountRows: (state, action) => action.payload
  }
})

// Create a slice for cupDatos
const clientesDatosSlice = createSlice({
  name: 'clientesDatos',
  initialState: [],
  reducers: {
    setReduxclientesAdminDatos: (state, action) => action.payload
  }
})

// Export the actions
export const { setReduxclientesAdminFiltrosBusqueda } = clientesFiltrosBusquedaSlice.actions
export const { setReduxclientesAdminPage } = clientesPageSlice.actions
export const { setReduxclientesAdminLimit } = clientesLimitSlice.actions
export const { setReduxclientesAdminCountRows } = clientesCountRowsSlice.actions
export const { setReduxclientesAdminDatos } = clientesDatosSlice.actions

// Combine the slices into one reducer
const storeClientesAdmin = {
  reduxFiltrosBusquedaCA: clientesFiltrosBusquedaSlice.reducer,
  reduxPageCA: clientesPageSlice.reducer,
  reduxLimitCA: clientesLimitSlice.reducer,
  reduxCountRowsCA: clientesCountRowsSlice.reducer,
  reduxclientesDatosCA: clientesDatosSlice.reducer
}

export default storeClientesAdmin

