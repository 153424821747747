import { createSlice } from '@reduxjs/toolkit'

// Create a slice for filtrosBusqueda
const contratosFiltrosBusquedaSlice = createSlice({
  name: 'filtrosBusqueda',
  initialState: [],
  reducers: {
    setReduxcontratosFiltrosBusqueda: (state, action) => action.payload
  }
})

// Create a slice for page
const contratosPageSlice = createSlice({
  name: 'page',
  initialState: 1,
  reducers: {
    setReduxcontratosPage: (state, action) => action.payload
  }
})

// Create a slice for limit
const contratosLimitSlice = createSlice({
  name: 'limit',
  initialState: 10,
  reducers: {
    setReduxcontratosLimit: (state, action) => action.payload
  }
})

// Create a slice for countRows
const contratosCountRowsSlice = createSlice({
  name: 'countRows',
  initialState: 0,
  reducers: {
    setReduxcontratosCountRows: (state, action) => action.payload
  }
})

// Create a slice for cupDatos
const contratosDatosSlice = createSlice({
  name: 'contratosDatos',
  initialState: [],
  reducers: {
    setReduxcontratosDatos: (state, action) => action.payload
  }
})

// Export the actions
export const { setReduxcontratosFiltrosBusqueda } = contratosFiltrosBusquedaSlice.actions
export const { setReduxcontratosPage } = contratosPageSlice.actions
export const { setReduxcontratosLimit } = contratosLimitSlice.actions
export const { setReduxcontratosCountRows } = contratosCountRowsSlice.actions
export const { setReduxcontratosDatos } = contratosDatosSlice.actions

// Combine the slices into one reducer
const storeContratos = {
  reduxFiltrosBusquedaC: contratosFiltrosBusquedaSlice.reducer,
  reduxPageC: contratosPageSlice.reducer,
  reduxLimitC: contratosLimitSlice.reducer,
  reduxCountRowsC: contratosCountRowsSlice.reducer,
  reduxcontratosDatosC: contratosDatosSlice.reducer
}

export default storeContratos

